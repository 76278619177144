import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import SimplePageBlock from "../components/Blocks/SimplePageBlock"
import PageBlock from "../components/Blocks/PageBlock"
import styled from "styled-components"
import CollectWidget from "../components/Collect/CollectWidget"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Hero from "../components/Collect/Hero"
import Blocks from "../components/HowItWorks/Blocks"


const HeadingBox = styled.div`
  float: left;
  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
    float: auto;
    width: 100%
  }
`

const ButtonBox = styled.div`
float: right;

  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: center;
    float: auto;
    width: 100%;
  }
`

const HaveContainersDiv = styled.div`
    height:100px;
    padding-left: 3vw;
    padding-right: 3vw;
    @media (max-width: ${breakpoints.md}) {
      height:275px;
      display: block;

  }
`

const HaveContainersButtonContainer = styled.div`
  float: right;
  @media (max-width: ${breakpoints.md}) {
    
      float: auto;
      width:100%;
  }
`

const Button = styled.button`

  position: relative;
  ${'' /* width: 194px; */}
  height: 55px;

  background: #319AD5;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;


  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  ${'' /* line-height: 33px; */}
  /* identical to box height */
  margin: 15px;
  text-align: center;

  color: #FFFFFF;

  @media (max-width: ${breakpoints.md}) {
    

      
  }
`
const HaveContainersHeading = styled.h2`

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  /* identical to box height */
  text-transform: capitalize;
  ${'' /* //text-align: left; */}
  float: left;
  margin: 25px;
  color: ${colors.royalBlue};
 
  @media (max-width: ${breakpoints.md}) {
    break-after: always;
    text-align: center;
    float: auto;
  }

`

const StyledLink = styled(Link)`
  @media (max-width: ${breakpoints.md}) {
    break-after: always;
   
  }
`

const HeroContainer = styled.div`
  ${'' /* padding-top: ${MOBILE_HEADER_HEIGHT}px;
 

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT}px;
  } */}
`

const StyledPageBlock = styled(PageBlock)`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }

  @media (min-width: ${breakpoints.md}) {
    width: 60%;
    margin: auto;
  }

  color: ${colors.black};
  ul {
    list-style-type: disc;
    margin 0.5em 0;
    padding-inline-start: 40px;
  }
  ol {
    list-style-type: decimal;
    margin 0.5rem 0;
    padding-inline-start: 40px;
  }
  p {
    margin: 0.5rem 0;
    font-size: calc(0.24vw + 1rem);
    line-height: 1.6;
  }
  a {
    color: ${colors.royalBlue};
  }
  b {
    ${fonts.workSansBold}
  }
  h2 {
    color: ${colors.royalBlue};
    line-height: 1.104;
    ${fonts.workSansBold};
    margin: 2rem 0;
    text-align: center;
  }
  h4 {
    margin-top: 83px;
  }
`

const CollectPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage
  const hero = page.hero;

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout  bg={colors.white}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
     
       { hero ?  <HeroContainer>
                  <Hero
                    heroData={hero}>
                      
                  </Hero>
                </HeroContainer>
      : ""}
<HaveContainersDiv>
<HeadingBox>
<HaveContainersHeading>
    Have Containers?
</HaveContainersHeading>
</HeadingBox>

  <HaveContainersButtonContainer>
  
<ButtonBox>
  <StyledLink to={"/account/containers"}>
    <Button>CHECK DUE DATES</Button>
  </StyledLink>
  
  </ButtonBox>
  <ButtonBox>
  <StyledLink to={"https://eatigc.typeform.com/to/XKVe9fDy"}>
    <Button>SCHEDULE PICK-UP</Button>
  </StyledLink>
</ButtonBox>
  </HaveContainersButtonContainer>
</HaveContainersDiv>
<Blocks blocks={page.blocks} />
    </Layout>
  )
}

export default CollectPage

export const pageQuery = graphql`
  query CollectQuery {
    contentfulPage(slug: { eq: "collect" }) {
      ...pageFragment
      blocks {
        __typename
        ... on ContentfulCollectBlock {
          
          id
          title
          backgroundColor
          blockFooter {
            raw
          }
          eyebrowIcons {
            gatsbyImageData(width: 32)
            description
          }
          firstSection
          secondSection {
            __typename
            ... on ContentfulSimpleBlock {
              image {
                gatsbyImageData
                description
              }
              content {
                raw
              }
            }
          }
          faqSection {
              question
              answer {
                raw
              }
            
          }
        }
  
  
      }
      hero {
        video {
          file {
            url
          }
        }
        title
        content {
          raw
        }
        ctaLabel
        ctaUrl
        image {
          gatsbyImageData
        }
      }
    }
    
  }
`
